import React from "react";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import Card from "react-bootstrap/Card";
import Tilt from "react-tilt";
import "./experience.styles.css";

const Experience = () => {
  return (
    <div id="experience">
      <h1 className="pt-3 text-center font-details-b pb-3">EXPERIENCE</h1>
      <Jumbotron className="jumbo-style">
        <Container>
          <Tilt options={{ max: 25 }}>
            <Card>
              <Card.Header as="h5" className="d-flex justify-content-center flex-wrap">
              <Card.Title className="text-center">California State University, Fresno</Card.Title>
              </Card.Header>
              <Card.Body className="d-flex justify-content-center flex-column">
                <div>
                  <Card.Title className="text-center">Supplemental Instruction (SI) Leader</Card.Title>
                </div>
                <div>
                  <Card.Text className="text-center style">
                    <strong className="body-title-style ">The Learning Center</strong>
                    <br />
                    <strong>Description:</strong> Facilitate and Innovate virtual academic support sessions for Introduction to Programming and Problem Solving (CSci 40). 
                    <br />
                    <strong>Technology:</strong> C++
                    <br />
                    <strong>Duration:</strong> Aug 2020 - Present
                  </Card.Text>
                  <br />
                </div>
                <div>
                  <Card.Title className="text-center">Administrative Student Leader</Card.Title>
                </div>
                <div>
                  <Card.Text className="text-center style">
                    <strong className="body-title-style ">International Office</strong>
                    <br />
                    <strong>Description:</strong> Support Administrative Assistant to the Assistant Vice President of International Affairs in program development and advancement. Managing the social media accounts of the International Office to promote engagement. 
                    <br />
                    <strong>Technology:</strong> Adobe Photoshop, Canva, HTML emails
                    <br />
                    <strong>Duration:</strong> Aug 2020 - Present
                  </Card.Text>
                </div>
              </Card.Body>
            </Card>
          </Tilt>
        </Container>
        <br />
        <Container>
          <Tilt options={{ max: 25 }}>
            <Card>
              <Card.Header as="h5" className="d-flex justify-content-center flex-wrap">
              <Card.Title className="text-center">Office of Community & Economic Development</Card.Title>
              </Card.Header>
              <Card.Body className="d-flex justify-content-center flex-column">
                <div>
                  <Card.Title className="text-center">Web Design Student Intern</Card.Title>
                </div>
                <div>
                  <Card.Text className="text-center style">
                    <strong>Description:</strong> Develop and designing websites for new clients. Updating websites of existing clients using WordPress. 
                    <br />
                    <strong>Technology:</strong> Wordpress, HTML, CSS, Javascript
                    <br />
                    <strong>Duration:</strong> Mar 2020 - Present
                  </Card.Text>
                </div>
              </Card.Body>
            </Card>
          </Tilt>
        </Container>
      </Jumbotron>
    </div>
    
  );
};

export default Experience;
