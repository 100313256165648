import React from "react";
import { Timeline, Events, ImageEvent } from "@merc/react-timeline"; //UrlButton

// projects
import Team_Connectwork from "../../assets/img/projects/team-connectwork.png";
import MICARD from "../../assets/img/projects/micard.png";
import INK from "../../assets/img/projects/iNeedKart.png";
import ISC from "../../assets/img/projects/isc.png";
import AC from "../../assets/img/projects/ac.jpg";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

// skills
import L_FLUTTER from "../../assets/img/skills/flutter.png";
import L_DART from "../../assets/img/skills/dart.svg";
import Image from "react-bootstrap/Image";
import L_HTML5 from "../../assets/img/skills/html-5.svg";
import L_CSS3 from "../../assets/img/skills/css3.svg";
import L_FLASK from "../../assets/img/skills/flask.svg";
import L_MSSQL from "../../assets/img/skills/mssql.svg";
import L_JAVASCRIPT from "../../assets/img/skills/javascript.svg";
import L_php from "../../assets/img/skills/php.svg";

import "./projects-timeline.styles.css";

const TimeLine = () => {
  return (
    <div id="projects">
      <h1 className="pt-3 text-center font-details-b pb-3">PROJECTS</h1>
      <Timeline>
        <Events>
          {/* Project: Team Connectwork */}
          <ImageEvent date="Aug 2020" className="text-center" text="Connectwork" src={Team_Connectwork} alt="Get GitHub Info">
            <div className="d-flex justify-content-between flex-column mt-1">
              <div>
                <Accordion>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0" className="p-2 text-center accordian-main">
                      More Details
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0" className="text-left">
                      <Card.Body>
                        <strong>Description:</strong> This app won 3rd place in "HackInPlace" Hackathon orgranized by a non-profit A2CN. An app that connects people with similar interests.
                        <hr />
                        <strong>Highlights:</strong>
                        <ul className="list-styles pt-1">
                          <li>Learning in Place - match students who require assistance in classes with peers who have exceled in the same class</li>
                          <li>Connecting in Place - match students to clubs and organizations based on their interests</li>
                          <li>Wellness in Place - match students with student mentors who need help with mental health issues</li>
                        </ul>
                        <hr />
                        <strong>Tech used:</strong>
                        <ul>
                          <li>
                            <span className="p-2">
                              <Image src={L_HTML5} alt="HTML 5" rounded className="image-style m-1"></Image> HTML5
                            </span>
                          </li>
                          <li>
                            <span className="p-2">
                              <Image src={L_CSS3} alt="CSS 3" rounded className="image-style m-1"></Image> CSS3
                            </span>
                          </li>
                          <li>
                            <span className="p-2">
                              <Image src={L_FLASK} alt="React" rounded className="image-style1 m-1"></Image> Flask
                            </span>
                          </li>
  
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </div>
          </ImageEvent>

          {/* Project: Business Card Application */}
          <ImageEvent date="Jul 2020" className="text-center" text="Business Card Android/iOS Application" src={MICARD} alt="Business Card Android/iOS Application">
            <div className="d-flex justify-content-between flex-column mt-1">
              <div>
                <Accordion>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0" className="p-2 text-center accordian-main">
                      More Details
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0" className="text-left">
                      <Card.Body>
                        <strong>Description:</strong> An application that is a business card and displays information of the user.
                        <hr />
                        <strong>Highlights:</strong>
                        <ul className="list-styles pt-1">
                          <li>Created using Flutter UI kit by Google</li>
                          <li>It works with both Android and iOS devices</li>
                        </ul>
                        <hr />
                        <strong>Tech used:</strong>
                        <ul>
                          <li>
                            <span className="p-2">
                              <Image src={L_FLUTTER} alt="Flutter" rounded className="image-style1 m-1"></Image> Flutter UI Kit
                            </span>
                          </li>
                          <li>
                            <span className="p-2">
                              <Image src={L_DART} alt="Dart" rounded className="image-style1 m-1"></Image> Dart Programming Language
                            </span>
                          </li>
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </div>
          </ImageEvent>

{/* Project: Fresno State ISC */}
<ImageEvent date="Oct 2019" className="text-center" text="Indian Student Club" src={ISC} alt="Indian Student Club">
            <div className="d-flex justify-content-between flex-column mt-1">
              <div>
                <Accordion>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0" className="p-2 text-center accordian-main">
                      More Details
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0" className="text-left">
                      <Card.Body>
                        <strong>Description:</strong> This website is for the student orgranization at Fresno State called Indian Student Club
                        <hr />
                        <strong>Highlights:</strong>
                        <ul className="list-styles pt-1">
                          <li>Developed and designed website for the organization (www.fresnostateisc.com)</li>
                        </ul>
                        <hr />
                        <strong>Tech used:</strong>
                        <ul>
                          <li>
                            <span className="p-2">
                              <Image src={L_HTML5} alt="HTML 5" rounded className="image-style m-1"></Image> HTML5
                            </span>
                          </li>
                          <li>
                            <span className="p-2">
                              <Image src={L_CSS3} alt="CSS 3" rounded className="image-style m-1"></Image> CSS3
                            </span>
                          </li>
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </div>
          </ImageEvent> 

          {/* Project: iNeedKart */}
           <ImageEvent date="May 2014" className="text-center" text="iNeedKart" src={INK} alt="iNeedKart">
            <div className="d-flex justify-content-between flex-column mt-1">
              <div>
                <Accordion>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0" className="p-2 text-center accordian-main">
                      More Details
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0" className="text-left">
                      <Card.Body>
                        <strong>Description:</strong> Designed, developed, tested, and deployed an e-commerce website (www.ineedkart.com) using open-source CMS to help local grocery stores & small businesses to establish their online presence
                        <hr />
                        <strong>Highlights:</strong>
                        <ul className="list-styles pt-1">
                          <li><strong>Accepted by Google’s Developer Relations Team - Startup Launch Program</strong></li>
                          <li>Collaborated with 10+ local grocery and medical supply businesses to assist them overcome corporate competition</li>
                          <li>Coached business owners to upload & maintain supply stock, conduct online purchases, and establish delivery methods</li>
                        </ul>
                        <hr />
                        <strong>Tech used:</strong>
                        <ul>
                          <li>
                            <span className="p-2">
                              <Image src={L_HTML5} alt="HTML5" rounded className="image-style1 m-1"></Image> HTML5
                            </span>
                          </li>
                          <li>
                            <span className="p-2">
                              <Image src={L_CSS3} alt="CSS3" rounded className="image-style m-1"></Image> CSS3
                            </span>
                          </li>
                          <li>
                            <span className="p-2">
                              <Image src={L_php} alt="PHP" rounded className="image-style1 m-1"></Image> PHP
                            </span>
                          </li>
                          <li>
                            <span className="p-2">
                              <Image src={L_MSSQL} alt="MYSQL" rounded className="image-style1 m-1"></Image> MySQL
                            </span>
                          </li>
                          <li>
                            <span className="p-2">
                              <Image src={L_JAVASCRIPT} alt="Javascript" rounded className="image-style1 m-1"></Image> JavaScript
                            </span>
                          </li>
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </div>
          </ImageEvent> 

          {/* Project: AuthenticChef */}
          <ImageEvent date="In Progress" className="text-center" text="AuthenticChef" src={AC} alt="AuthenticChef">
            <div className="d-flex justify-content-between flex-column mt-1">
              <div>
                <Accordion>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0" className="p-2 text-center accordian-main">
                      More Details
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0" className="text-left">
                      <Card.Body>
                        <strong>Description:</strong> This is a term project for my class Introduction to Software Engineering - CSci 150
                        <hr />
                        <strong>Features:</strong>
                        <ul className="list-styles pt-1">
                          <li>Hosts can market and sell their food online </li>
                          <li>Diners can either order delivery or visit hosts for an authentic dining experience</li>
                          <li>Fully funtional Administration which will approve hosts and diners</li>
                          <li>Using MERN Stack</li>
                          <li>Chat system</li>
                          <li>Payment feature</li>
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </div>
          </ImageEvent> 
        </Events>
      </Timeline>
    </div>
  );
};

export default TimeLine;
